<template>
  <ul class="nav metismenu" id="side-menu" ref="side-menu">
      <li class="nav-header">
        <div class="logo-element">
          <img alt="image" src="/img/brand_logo.png"/>
          <span v-if="!mode.match(/production/gi)" style="margin-left: 0.5rem;">{{ mode }}</span>
        </div>
        <div class="dropdown profile-element">
          <div class="profile-image">
            <img alt="image" class="rounded-circle" src="/img/profile_default.png"/>
          </div>
          <a data-toggle="dropdown" class="dropdown-toggle" href="/profile">
            <span class="block m-t-xs font-bold">{{profile.admin_name}} (id: <em>{{profile.admin_id}}</em>)</span>
            <!-- <span class="text-muted text-xs block">menu <b class="caret"></b></span> -->
          </a>
          <ul class="dropdown-menu fadeInRight m-t-xs">
              <li><a class="dropdown-item" href="#">Logout</a></li>
          </ul>
        </div>
      </li>

      <SideMenuItem v-for="route in items" :key="route.name" :item="route" :depth="0" v-if="!route.hidden && (!route.meta ? true : route.meta.admin_level <= $store.state.session.profile.admin_level)" />
  </ul>
</template>

<script>
import routes from "@/router/routes"
import MetisMenu from "metismenujs"
import SideMenuItem from "./SideMenuItem"

export default {
  mounted() {
    this.menu = new MetisMenu(this.$refs['side-menu'], {
      toggle: false
    })
    this.menu.dispose()
  },
  data() {
    console.log( "routes", routes );
    return {
      profile: this.$store.getters.profile || {},
      items: routes || [],
      mode: (process.env.VUE_APP_MODE || process.env.NODE_ENV).toUpperCase(),
    }
  },
  components: {
    SideMenuItem
  }
}
</script>
