<template>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <router-link to="/">Home</router-link>
    </li>

    <li class="breadcrumb-item" v-for="(item, index) in items" :key="item.name">
      <router-link :to="item" v-if="index < items.length - 1">{{item.meta.title}}</router-link>
      <b v-if="index === (items.length - 1)">{{item.meta.title}}</b>
    </li>
  </ol>
</template>

<script>
export default {
  data() {
    return {
      items: []
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.name)
      this.$data.items = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    }
  }
}
</script>
