<template>
  <div class="menu-trigger" @click="onClick">
    <button>
			<span></span>
		</button>
  </div>
</template>

<script>
export default {
  methods: {
    onClick(e) {
      this.$emit("click", e)
    }
  }
}
</script>
