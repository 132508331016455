<template>
  <div class="menu-more" >
    <button @click="onClick" >
			<span></span>
		</button>
  </div>
</template>

<script>
export default {
  methods: {
    onClick(e) {
      this.$emit("click", e)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-more {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding: 10px 0;

  button {
    width: 34px;
    height: 34px;
    line-height: 0;
    vertical-align: middle;
    position: relative;

    padding: 0; margin: 0;
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none !important;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      top: 13px;
      left: 16px;
      width: 3px;
      height: 3px;
      border-radius: 50% !important;
      background: #5d5f77;
      -webkit-transition: all .4s ease;
      transition: all .4s ease;
      cursor: pointer;

      &:before {
        background: #5d5f77;
        position: absolute;
        display: block;
        top: -8px;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 50% !important;
        content: "";
        border-radius: 0px;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
      }

      &:after {
        background: #5d5f77;
        position: absolute;
        display: block;
        bottom: -8px;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 50% !important;
        content: "";
        border-radius: 0px;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
      }
    }

    &:hover,
    &:focus {
      span {
        background: #000;

        &:before {
          background: #000;
          top: -9px;
        }

        &:after {
          background: #000;
          bottom: -9px;
        }
      }
    }
  }
}
</style>
