<template>
  <div class="row border-bottom">
      <nav class="navbar navbar-fixed-top gray-bg" role="navigation" style="margin-bottom: 0">
          <div class="navbar-header">
            <MenuTrigger @click.stop="onClickMenu" />
            <div class="navbar-utils">

            </div>
          </div>
          <div class="nav navbar-top-links">
            <MenuMore @click="onClickMore" v-click-outside="onClickMoreOutside" />

            <ul ref="menu" class="dropdown-menu fadeInRight m-t-xs" :class="{'show': showMenu}" >
              <li><a class="dropdown-item" href="javascript:void(0)" @click="onClickLogout">Logout</a></li>
            </ul>
          </div>
      </nav>
  </div>
</template>

<script>
import { MenuTrigger, MenuMore } from "./Menu"
export default {
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    onClickMenu(e) {
      this.$store.dispatch('layout/toggleSideBar', {})
    },
    onClickMore(e) {
      this.$data.showMenu = true
    },
    onClickMoreOutside(e) {
      this.$data.showMenu = false
    },
    onClickLogout() {
      this.$store.dispatch('session/logout', {})
        .then(() => {
          this.$router.replace({ name: 'Login' })
        })
        .catch(e => {
          
        })
    }
  },
  components: {
    MenuTrigger, MenuMore
  }
}
</script>
