<template>
  <li :class="{'active': isActive, 'expend': isExpendedChildren}">
    <a href="javascript:void(0)" @click.stop="onClickItem">
      <fa-icon :icon="icon" class="icon" v-if="icon" />
      <span class="nav-label">{{title}}</span>
      <fa-icon icon="angle-left" class="arrow" v-if="hasSubmenu" />
    </a>

    <!-- <router-link :to="resolvePath(item)" :exact="item.exact" @click.stop="onClickItem">
      <fa-icon :icon="icon" class="icon" v-if="icon" />
      <span class="nav-label">{{title}}</span>
      <fa-icon icon="angle-left" class="arrow" v-if="hasSubmenu" />
    </router-link> -->

    <ul ref="sub" class="nav nav-second-level collapse" :class="{'in': isExpendedChildren}" :style="expendedStyle" v-if="hasSubmenu">
      <SideMenuItem v-for="route in item.children.filter( route => !route.hidden )" :key="route.name" :item="route" :depth="depth + 1"/>
    </ul>
  </li>
</template>

<script>
import path from "path"
import SideMenuItem from "./SideMenuItem"
import utils from '@/utils'

export default {
  name: 'SideMenuItem',
  mounted() {
    if (this.isActive && this.depth === 0) {
      this.$store.dispatch('layout/toggleSideMenuItem', { item: this.item })
    }
  },
  props: {
    item: {
      type: Object,
      default: ''
    },
    depth: {
      type: Number,
      default: 0
    },
  },
  computed: {
    children() {
      let children = this.item.children && this.item.children.filter(item => item.hidden !== true && item) || []
      return children
    },

    hasChildren() {
      return this.children && this.children.length > 0 ? true : false
    },

    hasSubmenu() {
      if (this.item.meta) {
        return this.children && this.children.length > 0 ? true : false
      }

      return this.item.children && this.item.children.length > 1 ? true : false
    },

    isCollapsedSideBar() {
      return this.$store.getters.isCollapsedSideBar
    },

    isExpendedChildren() {
      if (this.isCollapsedSideBar) {
        return true
      }

      return this.$store.getters.expendedSideMenuItem === this.item ? true : false
    },

    expendedStyle() {

      if (this.isCollapsedSideBar) {
        return {}
      }

      let sub = this.$refs['sub'] && this.$refs['sub']
      let subHeight = sub && sub.scrollHeight || 0

      return {
        'height': (this.isExpendedChildren ? subHeight : 0) + 'px'
      }
    },

    activeDepth() {
      let matched = this.$route.matched.map(item => item.name)
      return matched.indexOf(this.item.name)
    },

    isActive() {
      if (this.activeDepth !== -1) {
        return true
      }

      return false
    },

    icon() {
      if (this.item.meta && this.item.meta.icon) {
        return this.item.meta.icon
      }

      if (this.item.children && this.item.children.length > 0) {
        let children = this.item.children[0]
        if (children.meta && children.meta.icon) {
          return children.meta.icon
        }
      }

      if (this.depth === 0) {
        return 'question'
      }

      return ''
    },

    title() {
      if (this.item.meta) {
        return this.item.meta.title
      }

      if (this.item.children && this.item.children.length > 0) {
        let children = this.item.children[0]
        if (children.meta) {
          return children.meta.title
        }
      }

      return 'Untitle'
    }
  },
  methods: {
    onClickItem(e) {
      e.preventDefault()

      if (this.depth === 0) {
        this.$store.dispatch('layout/toggleSideMenuItem', { item: this.item })
      }

      //if (!this.$utils.isMobile && this.isCollapsedSideBar || !this.hasSubmenu) {
      if (this.isCollapsedSideBar || !this.hasSubmenu) {
        if (this.item.name !== this.$route.name) {
          let params = {}

          if (this.item.keepQuery) {
            params = this.$route.params
          }
          if(this.item.name == "Tickets"){
            if(this.$route.name == "ListLuggage"){
              this.$router.push({ name: 'ListDelivery',  params: params}).then( async () => utils.isMobile ? await setTimeout(async () => this.$store.dispatch('layout/toggleSideBar', {}), 0) : '')
            }else{
              this.$router.push({ name: 'ListLuggage',  params: params}).then( async () => utils.isMobile ? await setTimeout(async () => this.$store.dispatch('layout/toggleSideBar', {}), 0) : '')
            }
          }else{
            this.$router.push({
              name: this.item.name,
              params: params
            }).then( async () => utils.isMobile ? await setTimeout(async () => this.$store.dispatch('layout/toggleSideBar', {}), 0) : '')
          }
        }
      }

      return false
    }
  }
}
</script>
