<template>
  <div class="footer">
    <div>
      <strong>Copyright</strong> Bluewhale Company &copy; 2018
    </div>
  </div>
</template>

<script>
export default {

}
</script>
