<template>
  <div id="wrapper">
    <Navigation />

    <div id="page-wrapper" class="gray-bg" @click.stop="closeSideBar">
      <TopHeader />

      <Heading />

      <keep-alive :include="cachedViews">
        <router-view :key="key"/>
      </keep-alive>

      <Footer />
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/layouts/Navigation"
import TopHeader from "@/components/layouts/TopHeader"
import Heading from "@/components/layouts/Heading"
import Footer from "@/components/layouts/Footer"
import utils from '@/utils'

export default {
  components: {
    Navigation,
    TopHeader,
    Heading,
    Footer
  },
  computed: {
    cachedViews() {
      return this.$store.getters.cachedViews
    },
    key() {
      return this.$route.fullPath
    }
  },
  created() {
    this.$progress.start()
  },

  mounted(){
    this.closeSideBar();
  },

  methods: {
    async closeSideBar(){
      if(!utils.isMobile) return;
      await this.$store.dispatch('layout/closeSideBar', {})
    }
  }
}
</script>
