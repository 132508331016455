<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <SideMenu />

      <div class="version-info">
        <span>{{version}}</span>
      </div>
    </div>
  </nav>
</template>

<script>

import { SideMenu } from "./Menu"
import config from "@/config"

export default {
  components: {
    SideMenu
  },
  computed: {
    version() {
      return `v ${config.version}`
    }
  }
}
</script>
