<template>
<div class="row wrapper border-bottom white-bg page-heading">
    <div class="page-heading-content">
        <h2>{{title}}</h2>
        <Breadcrumb />
    </div>
</div>
</template>

<script>
import Breadcrumb from './Breadcrumb'

export default {

  components: {
    Breadcrumb
  },
  computed: {
    title() {
      if (this.$route.matched && this.$route.matched.length > 0) {
        let matched = this.$route.matched[0]
        if (matched.meta && matched.meta.title) {
          return matched.meta && matched.meta.title
        }
      }
      return this.$route.meta && this.$route.meta.title || 'Untitle'
    }
  }
}
</script>
